import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import amazonReportService from '@/http/requests/reports/amazonReportService.js'
import Pager from '@core/dev-extreme/utils/pager'
// import camelCase from 'lodash/camelCase'

const amazonMonthlySalesStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  // eslint-disable-next-line no-unused-vars
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    // pager.setSortOrder(sort)
    const sortString = `${sort[0]?.selector},${sort[0]?.desc ? 'desc' : 'asc'}`
    const pageableQuery = `${pager.toCreatePageable()}&sort=${sortString}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : {}
    const response = await amazonReportService.getMonthlySales(pageableQuery, filters)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const amazonMonthlySalesDataSource = new DataSource({
  store: amazonMonthlySalesStore,
})

export {
  amazonMonthlySalesDataSource as AmazonMonthlySalesDataSource,
}
