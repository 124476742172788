<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="amazonMonthlySalesGrid"
          ref="amazonMonthlySalesGridRef"
          :height="setHeight"
          :data-source="AmazonMonthlySalesDataSource"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @editor-preparing="onEditorPreparing"
          @toolbar-preparing="onToolbarPreparing($event)"
        >
          <!--region    ::DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion ::DataGrid base components -->
          <dx-column data-field="*" caption="Chart" :width="53" cell-template="chartButtonCellTemplate" />
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="70" caption="Image" alignment="right" />
          <template #imageCellTemplate="{data}">
            <div>
              <img class="rounded img-fluid img-thumbnail" :src="data.value || defaultImageUrl" style="height:72px;width:72px;">
            </div>
          </template>
          <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" :min-width="240" />
          <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
          <dx-column data-field="amzRank" :width="60" caption="Rank" />
          <dx-column data-field="totalQuantity" caption="Total Qty" />
          <dx-column data-field="totalSales" caption="Total Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" />

          <dx-column :caption="formatDate(1)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity1" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales1" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(2)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity2" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales2" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(3)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity3" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales3" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(4)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity4" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales4" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(5)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity5" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales5" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(6)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity6" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales6" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(7)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity7" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales7" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(8)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity8" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales8" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(9)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity9" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales9" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(10)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity10" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales10" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(11)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity11" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales11" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(12)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity12" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales12" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(13)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity13" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales13" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(14)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity14" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales14" caption="Sales " :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(15)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity15" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales15" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(16)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity16" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales16" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(17)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity17" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales17" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(18)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity18" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales18" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(19)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity19" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales19" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(20)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity20" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales20" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(21)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity21" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales21" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(22)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity22" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales22" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(23)" alignment="center">
            <dx-column data-field="quantity23" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales23" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>
          <dx-column :caption="formatDate(24)" alignment="center">
            <dx-column :visible="selectedType !== 'Sales'" data-field="quantity24" caption="Qty" alignment="right" />
            <dx-column :visible="selectedType !== 'Quantity'" data-field="totalSales24" caption="Sales" :format="{ style: 'currency', precision: 2, currency: 'USD' }" alignment="right" />
          </dx-column>

            <template #chartButtonCellTemplate="{data}">
            <div class="py-1" @mouseleave="closePopover">
              <dx-util-button icon="bi bi-graph-up-arrow" type="default" @click="togglePopover(data)" />
            </div>
          </template>
            <template #titleCellTemplate="{data}">
            <div :id="`title-${data.data.asin}`" class="dx-product-title py-1">
              {{ data.value }}
            </div>
          </template>
          <template #asinCellTemplate="{data}">
            <a target="blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <template #toolbarFilters>
            <dx-util-select-box
              v-model="selectedType"
              :data-source="options"
              display-expr="text"
              value-expr="value"
            />
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
      <dx-util-popover
        :target="hoveredItem"
        :visible="isPopoverVisible"
        position="right"
        :defer-rendering="false"
        :close-on-outside-click="true"
      >
        <template>
          <div>
            <DxChart
            id="chart"
            ref="monthlySalesChartRef"
            :data-source="chartData"
            :customize-label="customizeLabel"
          >
            <DxTitle :text="title">
              <DxFont :size="21" />
            </DxTitle>
            <DxCommonSeriesSettings argument-field="month" />
            <DxLabel format="decimal" />
            <DxSeries
              axis="quantity"
              argument-field="month"
              value-field="quantity"
              name="Quantity"
              type="bar"
              color="#1EB2F6"
            />
            <DxSeries
              axis="totalSales"
              argument-field="month"
              type="spline"
              value-field="totalSales"
              name="Sales"
              color="#F77F00"
            />
            <DxValueAxis name="quantity" :tick-interval="1">
              <DxTitle text="Quantity">
                <DxFont color="#1EB2F6" />
              </DxTitle>
              <DxLabel :customize-text="customizeText">
                <DxFont color="#1EB2F6" />
              </DxLabel>
            </DxValueAxis>
            <DxValueAxis name="totalSales" position="right">
              <DxTitle text="Sales">
                <DxFont color="#F77F00" />
              </DxTitle>
              <DxLabel :customize-text="customizeText">
                <DxFont color="#F77F00" />
              </DxLabel>
            </DxValueAxis>
            <DxSize :width="1200" :height="800" />
            <DxArgumentAxis :tick-interval="1" />
            <DxLegend :visible="true" oriantation="horizontal" horizontal-alignment="center" vertical-alignment="bottom" />
          </DxChart>
          <div class="text-center">
            <span class="text-primary">Total Quantity: </span>{{ allTotalQuantity }}
            <span class="text-warning ml-1">Total Sales: </span> {{ currencyFormatter(allTotalSales) }}
          </div>
          </div>
        </template>
        </dx-util-popover>
    </div>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import DxChart, {
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxValueAxis,
  DxTitle,
  DxFont,
  DxSize,
  DxLegend,
  DxArgumentAxis,
} from 'devextreme-vue/chart'
import { currencyFormatter } from '@/@core/utils/filter'
import amazonReportService from '@/http/requests/reports/amazonReportService.js'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import CoolLightBox from 'vue-cool-lightbox'
import { AmazonMonthlySalesDataSource } from './amazonMonthlySales'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
    // 'monthly-sales-chart': import('./components/MonthlySalesChart.vue'),
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxValueAxis,
    DxTitle,
    DxFont,
    DxSize,
    DxLegend,
    DxArgumentAxis,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      AmazonMonthlySalesDataSource,
      asin: '',
      title: '',
      defaultImageUrl,
      images: [],
      index: null,
      isPopoverVisible: false,
      hoveredItem: '',
      chartData: [],
      showChart: true,
      filters: {
        q: '',
      },
      allTotalSales: 0,
      allTotalQuantity: 0,
      currencyFormatter,
      selectedType: 'All',
      options: [
        { text: 'Show Columns (All)', value: 'All' },
        { text: 'Quantity', value: 'Quantity' },
        { text: 'Sales', value: 'Sales' },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      AmazonMonthlySalesDataSource.load()
    },
    loadDataByAsin() {
      amazonReportService.getMonthlySalesByAsin(this.asin)
    },
    async searchByFilter() {
      const filters = this.filters
      AmazonMonthlySalesDataSource.searchValue(filters)
      await AmazonMonthlySalesDataSource.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            AmazonMonthlySalesDataSource.reload()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.asin = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.asin = ''
            this.searchByFilter()
          }
        }
      }
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        AmazonMonthlySalesDataSource.pageIndex(0)
        AmazonMonthlySalesDataSource.reload()
      }
    },
    togglePopover(e) {
      const asin = e.data.asin
      this.asin = asin
      const upc = e.data.upc ? `| ${e.data.upc}` : ''
      this.title = `${e.data.title || ''} | ${e.data.asin} ${upc}`
      this.allTotalQuantity = e.data.totalQuantity
      this.allTotalSales = e.data.totalSales
      this.hoveredItem = `#title-${asin}`
      this.isPopoverVisible = true
      this.chartData = []
      for (let i = 1; i <= 24; i++) {
        const month = this.formatDate(i)
        const quantity = Number(e.data[`quantity${i}`])
        const totalSales = Number(e.data[`totalSales${i}`])
        this.chartData.push({ month, quantity, totalSales })
      }
    },
    formatDate(i) {
      const currentDate = new Date()
      const date = new Date()
      date.setMonth(currentDate.getMonth() - (24 - i))
      const yy = date.getFullYear().toString().slice(-2)
      const MM = (date.getMonth() + 1).toString().slice(-2)
      return `${yy}-${MM}`
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    customizeLabel(e) {
      if (e.value) {
        return {
          visible: true,
          backgroundColor: this.setLabelColor(e.seriesName),
          customizeText({ valueText }) {
            return e.seriesName === 'Quantity' ? `${valueText}` : `$${valueText}`
          },
        }
      }
      return null
    },
    customizeText({ valueText }) {
      return `${valueText}`
    },
    setLabelColor(seriesName) {
      if (seriesName === 'Quantity') return '#1EB2F6'
      if (seriesName === 'Sales') return '#F77F00'
      return '#000'
    },
    closePopover() {
      this.isPopoverVisible = false
    },
  },
}

</script>

<style scoped>

</style>
